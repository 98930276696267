<template>
  <div class="Authority">
    <Header title="合同列表" index="首页" titleOne="合同管理" beforeTitle="合同列表" />
    <div class="content">
      <div class="topUsers">
        <div>
          <el-select
            clearable
            @change="classSelect"
            v-model="contractParams.sign_org"
            placeholder="请选择签订部门"
          >
            <el-option
              v-for="item in options_class"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
          <el-select
            clearable
            @change="humanSelect"
            v-model="contractParams.value_human"
            placeholder="请选择签订人"
          >
            <el-option
              v-for="item in options_human"
              :key="item.id"
              :label="item.true_name"
              :value="item.id"
            ></el-option>
          </el-select>
          <el-select @change="statusSelect" v-model="value_status" placeholder="请选择状态">
            <el-option label="全部" value=" "></el-option>
            <el-option label="未提交" value="0"></el-option>
            <el-option label="已提交" value="1"></el-option>
            <el-option label="未通过" value="3"></el-option>
            <el-option label="已通过" value="2"></el-option>
            <el-option label="已流转" value="4"></el-option>
            <el-option label="已分配" value="5"></el-option>
          </el-select>
          <el-input
            v-model="contractParams.keywords"
            placeholder="请输入企业名称关键字"
            @keyup.enter.native="handleSearch()"
          ></el-input>
          <el-button class="search" @click="handleSearch()">查询</el-button>
        </div>
        <el-button
          v-if="privilige_list.contract.includes('add')"
          class="add"
          @click="editContract('')"
        >新增</el-button>
      </div>
      <el-table
        v-loading="loading"
        element-loading-text="正在加载中···"
        :data="contract.data"
        style="width: 100%"
      >
        <el-table-column prop="company_name" label="企业名称" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            {{
            scope.row.company_name ? scope.row.company_name : "-"
            }}
          </template>
        </el-table-column>
        <el-table-column prop="area" label="所属区域" :show-overflow-tooltip="true">
          <template slot-scope="scope">{{ scope.row.area ? scope.row.area : "-" }}</template>
        </el-table-column>
        <el-table-column prop="contract_name" label="合同名称" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            {{
            scope.row.contract_name ? scope.row.contract_name : "-"
            }}
          </template>
        </el-table-column>
        <el-table-column prop="ht_num" label="合同编号" :show-overflow-tooltip="true">
          <template slot-scope="scope">{{ scope.row.ht_num ? scope.row.ht_num : "-" }}</template>
        </el-table-column>
        <el-table-column prop="type" label="合同类型" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <span v-if="scope.row.type == 1">独立</span>
            <span v-if="scope.row.type == 2">非独立</span>
          </template>
        </el-table-column>
        <el-table-column prop="project_num" label="项目数量" :show-overflow-tooltip="true">
          <template slot-scope="scope">{{ scope.row.project_num ? scope.row.project_num : "-" }}</template>
        </el-table-column>
        <el-table-column prop="true_name" label="签订人" :show-overflow-tooltip="true">
          <template slot-scope="scope">{{ scope.row.true_name ? scope.row.true_name : "-" }}</template>
        </el-table-column>
        <el-table-column prop="create_time" label="创建时间" :show-overflow-tooltip="true">
          <template slot-scope="scope">{{ scope.row.create_time | fmtdate }}</template>
        </el-table-column>
        <el-table-column prop="status" label="状态" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <span class="state wtj" v-if="scope.row.status == 0">未提交</span>
            <span style="color:#FFBE2C" class="state ytj" v-if="scope.row.status == 1">已提交</span>
            <span class="state ylz" v-if="scope.row.status == 2">已通过</span>
            <div v-if="scope.row.status == 3">
              <el-tooltip
                class="item"
                effect="dark"
                :content="scope.row.reject_reason"
                placement="top-start"
              >
                <span class="state btg">未通过</span>
              </el-tooltip>
            </div>
            <span class="state ylz" v-if="scope.row.status == 4">已流转</span>
            <span class="state yfp" v-if="scope.row.status == 5">已分配</span>
          </template>
        </el-table-column>
        <el-table-column prop="operation" label="操作" width="480px">
          <template slot-scope="scope">
            <el-button
              class="edit"
              size="mini"
              @click="handleSubmit(scope.row.id)"
              v-if="privilige_list.contract.includes('render') && scope.row.status == 0 || scope.row.status == 3"
            >提交</el-button>
            <!-- <el-button class="edit" size="mini" v-if=" scope.row.status == 1 ">撤回</el-button> -->
            <el-button
              class="edit"
              size="mini"
              @click="editContract(scope.row.id)"
              v-if="privilige_list.contract.includes('edit') && scope.row.status != 1 && scope.row.status != 2"
            >编辑</el-button>
            <el-button
              v-if="privilige_list.contract.includes('flow') && scope.row.contract_flow == true && scope.row.status != 0 && scope.row.status != 1"
              class="edit"
              size="mini"
              @click="handleflow(scope.row.id)"
            >流转</el-button>
            <el-button
              class="edit"
              size="mini"
              @click="handleDetail(scope.row.id)"
              v-if="privilige_list.contract.includes('view')"
            >详情</el-button>
            <el-button
              v-if="privilige_list.contract.includes('view_file')"
              class="edit"
              size="mini"
              @click="handleFile(scope.row.id)"
            >附件</el-button>
            <el-button
              class="del"
              size="mini"
              @click="handleDelete(scope.row.id)"
              v-if="privilige_list.contract.includes('delete') && scope.row.m_id == myself_id && (scope.row.status == 3 || scope.row.status == 0) "
            >删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="block" v-if="contract.total > 0">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="contractParams.page"
          :page-sizes="[2, 10, 20, 40]"
          :page-size="contractParams.limit"
          layout="sizes, prev, pager, next,total,jumper"
          :total="contract.total"
        ></el-pagination>
      </div>

      <el-dialog title="提交" :visible.sync="dialogAudit" width="400px">
        <el-form
          :model="AuditForm"
          :rules="AuditFormRule"
          ref="auditFormRef"
          :label-position="labelPosition"
        >
          <el-form-item label="部门">
            <el-select
              v-model="AuditForm.role"
              placeholder="请选择部门"
              @change="getOrganization"
              style="width: 100%"
            >
              <el-option
                v-for="item in auditOptions"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="审核人">
            <el-select
              v-model="AuditForm.member"
              placeholder="请选择审核人"
              style="width: 100%"
              @change="getMemberId"
            >
              <el-option
                v-for="item in auditMember"
                :key="item.id"
                :label="item.true_name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="saveAudit()">确 定</el-button>
        </div>
      </el-dialog>
      <el-dialog title="流转" :visible.sync="dialogflowFormVisible" width="400px">
        <el-form
          :model="flowform"
          :rules="flowformRule"
          ref="flowformRef"
          :label-position="labelPosition"
        >
          <el-form-item label="流转类型">
            <el-select
              v-model="flowform.type"
              placeholder="请选择流转类型"
              @change="handleChangeType"
              style="width: 100%"
            >
              <el-option label="流转" :value="1"></el-option>
              <el-option label="分配" :value="2"></el-option>
              <el-option label="流转撤回" :value="3"></el-option>
              <el-option label="分配撤回" :value="4"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="部门" v-if="roleStatus" prop="role">
            <el-select
              v-model="flowform.role"
              placeholder="请选择部门"
              @change="getStaff"
              style="width: 100%"
            >
              <el-option v-for="item in role" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="typeHuman" v-if="typeStatus" prop="member">
            <el-select
              v-model="flowform.member"
              :placeholder="'请选择' + typeHuman"
              style="width: 100%"
              @change="getMemberId"
            >
              <el-option
                v-for="item in member"
                :key="item.id"
                :label="item.true_name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="xmText">
            <el-select
              v-model="flowform.contract_project"
              multiple
              placeholder="请选择项目/可多选"
              style="width: 100%"
            >
              <el-option
                v-for="item in this.contract_project.contract_project"
                :key="item.contract_project_id"
                :label="item.title"
                :value="item.contract_project_id"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="saveFlow()">确 定</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
export default {
  inject: ["reload"],
  data () {
    return {
      myself_id: '',//个人信息id
      options_human: [],//签订人下拉框
      options_class: [],//签订部门下拉框
      value_status: '',//状态筛选v-model
      loading: true,
      labelPosition: "top",
      contractTotal: 0,
      contractParams: {
        page: 1,
        limit: 10,
        keywords: "",
        order: "id desc",
        value_class: "",//签订部门v-model
        value_human: "",//签订人v-model
        sign_person: '',
        status: '',
      },
      AuditForm: {
        id: '',//合同id
      },
      AuditFormRule: {},
      auditOptions: [],//审核部门
      auditMan: '',//审核人
      auditMember: [],//审核人列表
      options: [],
      role: [],
      member: [],
      contract_project: [],
      visible: false,
      dialogFormVisible: false,
      dialogflowFormVisible: false,
      dialogAudit: false,//提交dialog框
      form: {
        role: "",
        member: "",
        contract_project: [],
        project_id: [],
        contract_id: "",
        member_id: "",
      },
      formRule: {},
      flowform: {
        role: "",
        member: "",
        contract_project: [],
        project_id: [],
        contract_id: "",
        member_id: "",
        type: '',//流转类型（流转/分配）
      },
      flowformRule: {},
      privilige_list: [],
      typeHuman: '',//流转人/分配人 默认状态
      typeStatus: false,//流转人/分配人 from表单默认隐藏
      roleStatus: true,//部门 from表单默认隐藏，流转/分配撤回需隐藏
      id: '',
      xmText: '项目',
    };
  },
  activated () {
    // this.handleSearch();
    this.contractParams
    this.$store.dispatch("getContractList", this.contractParams);
  },
  computed: {
    ...mapState(["contract"]),
  },
  created () {
    this.myself_id = localStorage.getItem('myself_id')
    this.getDepartment();
    this.getitemId();
    let data = localStorage.getItem('privilige')
    data = JSON.parse(data)
    this.privilige_list = data
    this.$store.dispatch("getContractList", this.contractParams);
  },
  methods: {
    handleChangeType () {
      this.$set(this.flowform, 'role', '')
      this.$set(this.flowform, 'member', '')
      this.contract_project = []
      this.$set(this.flowform, 'contract_project', '')
      if (this.flowform.type) this.typeStatus = true
      if (this.flowform.type == 1) {
        this.typeHuman = '流转人'
        this.roleStatus = true;
        this.xmText = '项目'
        this.axios
          .get("/api/contract/flow", {
            params: {
              contract_id: this.id,
            },
          })
          .then((res) => {
            console.log(res);
            this.contract_project = res.data;
            this.contract_id = res.data.contract_id;
          });
      }
      if (this.flowform.type == 2) {
        this.typeHuman = '分配人'
        this.roleStatus = true;
        this.xmText = '项目'
        this.axios
          .get("/api/contract/assign", {
            params: {
              contract_id: this.id,
            },
          })
          .then((res) => {
            console.log(res);
            this.contract_project = res.data;
            this.contract_id = res.data.contract_id;
          });
      }
      if (this.flowform.type == 3) {
        console.log('流转撤回')
        this.typeStatus = false;
        this.roleStatus = false;
        this.xmText = '流转撤回项目';
        this.axios
          .get("/api/contract/get_recall_data", {
            params: {
              contract_id: this.id,
              type: this.flowform.type
            },
          })
          .then((res) => {
            console.log(res);
            if (res.data) {
              this.contract_project = res.data;
              this.contract_id = res.data.contract_id;
            }
          });
      }
      if (this.flowform.type == 4) {
        console.log('分配撤回')
        this.typeStatus = false;
        this.roleStatus = false;
        this.xmText = '分配撤回项目'
        this.axios
          .get("/api/contract/get_recall_data", {
            params: {
              contract_id: this.id,
              type: this.flowform.type
            },
          })
          .then((res) => {
            console.log(res);
            if (res.data) {
              this.contract_project = res.data;
              this.contract_id = res.data.contract_id;
            }
          });
      }
    },
    // 提交合同选择部门change事件
    getOrganization (id) {
      this.axios.get('/api/organization/get_organization_reviewer', { params: { organization_id: id } }).then((res) => {
        this.auditMember = res.data
      })
    },
    statusSelect (sta) {
      console.log(sta)
      this.contractParams.status = sta
      this.$store.dispatch("getContractList", { ...this.contractParams, status: sta });
    },
    humanSelect (data) {
      this.contractParams.sign_person = data
      this.$store.dispatch("getContractList", { ...this.contractParams, sign_person: data });
    },
    classSelect (id) {
      this.value_human = ''
      this.axios
        .get("/api/member/select_member", { params: { organization_id: id } })
        .then((res) => {
          this.options_human = res.data;
        })
        .catch((err) => {
          console.log("err:", err);
        });
    },
    // 获取签订部门
    getitemId () {
      this.axios
        .get("/api/organization/select_organization")
        .then((res) => {
          this.options_class = res.data;
        });
    },
    handleSearch () {
      this.$store.dispatch("getContractList", { ...this.contractParams, page: '', limit: '' });
      this.contractParams.page = 1
    },
    handleSizeChange (val) {
      this.contractParams.limit = val;
      this.$store.dispatch("getContractList", this.contractParams);
    },
    handleCurrentChange (val) {
      this.contractParams.page = val;
      this.$store.dispatch("getContractList", { ...this.contractParams });
    },
    editContract (id) {
      this.$router.push("/contract/add?id=" + id);
    },
    handleDetail (id) {
      this.$router.push("/contract/detail?id=" + id);
    },
    handleflow (id) {
      this.id = id
      this.flowform = {};
      this.contract_project = [];
      this.dialogflowFormVisible = true;
    },

    getDepartment () {
      this.axios.get("/api/organization/select_organization").then((res) => {
        this.role = res.data;
        this.auditOptions = res.data
      });
    },
    getStaff (id) {
      console.log(id);
      console.log(this.flowform.type, 'type')
      this.$set(this.flowform, 'member', '')
      if (this.flowform.type == 1) {
        this.axios
          .get("/api/organization/get_organization_flow", {
            params: {
              organization_id: id,
            },
          })
          .then((res) => {
            console.log(res);
            this.member = res.data;
          });
      } else if (this.flowform.type == 2) {
        this.axios
          .get("/api/organization/get_organization_assign", {
            params: {
              organization_id: id,
            },
          })
          .then((res) => {
            console.log(res);
            this.member = res.data;
          });
      }
    },
    getStaffp (id) {
      this.axios
        .get("/api/member/select_member", {
          params: {
            role_id: id,
          },
        })
        .then((res) => {
          console.log(res);
          this.member = res.data;
        });
    },
    getMemberId (id) {
      this.member_id = id;
    },
    saveAudit () {
      console.log(this.AuditForm, '合同提交')
      this.axios.post("/api/contract/audit", {
        id: this.AuditForm.id,
        reviewer: this.AuditForm.member
      }).then(() => {
        this.$message({
          type: "success",
          message: "提交成功!",
        });
        this.$store.dispatch("getContractList", this.contractParams);
        this.dialogAudit = false;
      });
    },
    saveFlow () {
      this.$refs.flowformRef.validate((valid) => {
        if (valid) {
          if (this.flowform.type == 1) {
            this.axios
              .post("/api/contract/flow_contract", {
                contract_id: this.contract_id,
                project_id: this.flowform.contract_project.join(","),
                member_id: this.member_id,
              })
              .then((res) => {
                this.dialogflowFormVisible = false;
                console.log(res);
                this.$message.success("流转成功");
                this.$store.dispatch("getContractList", this.contractParams);
                this.reload();
                this.dialogflowFormVisible = false;
              })
              .catch((res) => {
                console.log("err:", res);
              });
            this.dialogflowFormVisible = false;
          }
          if (this.flowform.type == 2) {
            console.log(this.form.contract_project, 88888)
            this.axios
              .post("/api/contract/assign_contract", {
                contract_id: this.contract_id,
                project_id: this.flowform.contract_project.join(","),
                member_id: this.member_id,
              })
              .then((res) => {
                this.$message.success("分配成功");
                this.reload();
                this.$store.dispatch("getContractList", this.contractParams);
                this.dialogflowFormVisible = false;
              })
              .catch((res) => {
                console.log("err:", res);
              });
            this.dialogflowFormVisible = false;
          }
          if (this.flowform.type == 3) {
            this.axios
              .post("/api/contract/recall_contract_project", {
                contract_id: this.contract_id,
                project_id: this.flowform.contract_project.join(","),
                type: this.flowform.type
              })
              .then((res) => {
                this.$message.success(res.message);
                this.reload();
                this.$store.dispatch("getContractList", this.contractParams);
                this.dialogflowFormVisible = false;
              })
              .catch((res) => {
                console.log("err:", res);
              });
            this.dialogflowFormVisible = false;
          }
          if (this.flowform.type == 4) {
            this.axios
              .post("/api/contract/recall_contract_project", {
                contract_id: this.contract_id,
                project_id: this.flowform.contract_project.join(","),
                type: this.flowform.type
              })
              .then((res) => {
                this.$message.success(res.message);
                this.reload();
                this.$store.dispatch("getContractList", this.contractParams);
                this.dialogflowFormVisible = false;
              })
              .catch((res) => {
                console.log("err:", res);
              });
            this.dialogflowFormVisible = false;
          }
        } else {
          console.log("输入错误");
        }
      });
    },
    // saveAssign () {
    //   this.$refs.formRef.validate((valid) => {
    //     if (valid) {

    //     } else {
    //       console.log("输入错误");
    //     }
    //   });
    // },
    handleSubmit (id) {
      this.AuditForm = {}
      this.dialogAudit = true
      this.AuditForm.id = id
    },
    handleFile (id) {
      console.log("合同列表附件id：", id)
      this.$router.push({ path: '/contract/annex', query: { id: id } })
    },
    handleDelete (id) {
      console.log(id);
      this.$confirm("确定删除此项目数据，删除后无法恢复！", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then((res) => {
          console.log(res);
          this.axios.delete("/api/contract/delete", {
            params: {
              id: id,
            },
          }).then(() => {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.$store.dispatch("getContractList", this.contractParams);
          });
        })
        .catch((res) => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
  },
  beforeUpdate () {
    this.loading = false;
  },
};
</script>

<style scoped="scoped">
.btg {
  color: #ff6f7a;
  font-weight: bold;
}

.ytg {
  color: #cacddf;
  font-weight: bold;
}

.yfp,
.ylz {
  color: #1ec300;
  font-weight: bold;
}

.ytj {
  color: #1ec300;
  font-weight: bold;
}

.el-progress__text {
  color: #0f38ff;
  font-weight: bold;
  font-size: 14px;
}
div/deep/.el-dialog__header {
  font-weight: bold;
}
</style>
